<template>
	<UCard
		:ui="{
			base: 'px-0 sm:px-1 cursor-pointer',
			background: 'bg-transparent dark:bg-transparent',
			ring: 'ring-0 transition ease-in-out hover:ring-1 duration-300',
			divide: 'divide-y-0',
			rounded: 'rounded-none transition ease-in-out hover:rounded duration-300',
			shadow: 'shadow-none transition ease-in-out hover:shadow duration-300',

			body: {
				base: 'sm:min-h-[110px] lg:min-h-[80px] xl:min-h-[60px]',
				padding: 'py-0 px-0 sm:p-0 sm:px-1 sm:py-1',
			},
		}"
		@click="toKnowledgeDetail()"
	>
		<div class="w-full flex gap-3 sm:py-1">
			<NuxtImg :src="props.article.image?.path?.fs" class="size-20 cover-image rounded-[4px]" />

			<div class="w-full flex flex-col justify-between">
				<h3 class="text-sm sm:text-lg font-bold font-jakarta text-wemotooBlue title">
					{{ props.article.title }}
				</h3>
				<p class="text-xs sm:text-sm font-jakarta text-neutral-50 font-medium author mt-2">
					{{ props.article.author }}
				</p>
			</div>
		</div>
	</UCard>
</template>

<script lang="ts" setup>
import type { Knowledge } from '../../types';

const props = defineProps<{
	article: Knowledge;
}>();

const toKnowledgeDetail = async () => {
	const firebase = useFirebaseStore();

	firebase.logCustomEvent(SELECT_ARTICLE, {
		knowledge_id: props.article.id,
		knowledge_title: props.article.title,
	});

	navigateTo(`/knowledge/${props.article.id}`);
};
</script>

<style scoped lang="css">
.title {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.author {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp: 1;
	-webkit-box-orient: vertical;
}
</style>
