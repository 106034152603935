<template>
	<WemotooHeader />

	<div class="bg-white">
		<slot name="thumbnail" />

		<div class="wrapper">
			<div class="flex justify-start py-8 sm:py-14">
				<button class="button-center" @click="$router.back()">
					<UIcon name="i-heroicons-chevron-left" class="size-5 text-black text-center" />
					<p class="text-[14px] font-bold font-jakarta">Back</p>
				</button>
			</div>

			<div class="container-content no-scrollbar border-b-[1px] border-neutral-30">
				<slot name="content" />
			</div>

			<div class="py-8">
				<h1 class="font-jakarta font-semibold text-base sm:text-lg">More Articles</h1>
				<div class="grid grid-cols-1 sm:grid-cols-2 mt-4 gap-4">
					<ArticleTile v-for="article in recommendArticle" :key="article.id" :article="article" />
				</div>
			</div>
		</div>

		<WemotooFooter />
	</div>
</template>

<script lang="ts" setup>
const articleStore = useArticleStore();
const recommendArticle = articleStore.getRandomKnowledges(2);
</script>

<style scoped lang="css">
.wrapper {
	@apply flex flex-col container px-4 sm:mx-auto h-full max-w-[1026px];
}

.container-content {
	@apply w-full bg-white pb-8 sm:pb-14;
}

.sticky-div {
	position: sticky;
	bottom: 0;
	z-index: 1;
	background-color: white;
	box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
</style>
